import { useGtm, type TrackEventOptions } from "@gtm-support/vue-gtm";

export const useTrackEvent = (event: TrackEventOptions) => {
  const gtm = useGtm();

  if (gtm && gtm.enabled()) {
    gtm.trackEvent(event);
  } else {
    // We still want to add events to the dataLayer even though the GTM plugin is deactivated (e.g. due to missing cookie consent)
    window.dataLayer?.push(event);
  }
};

const clearPreviousEcommerce = () => {
  window.dataLayer?.push({ ecommerce: null });
};

export const useTrackCheckoutEvent = (event: TrackEventOptions) => {
  clearPreviousEcommerce();
  useTrackEvent(event);
};

export const useEmitFormSubmit = (formId: string) => {
  useTrackEvent({
    /** We don't use 'form_submit' because it would coincide with Google's
     * native form submission event.
     *
     * We don't want to use Google's native form submission event because
     * it fires even when a form is invalid.
     */
    event: "ps_form_submit",
    form_id: formId,
  });
};

export const useEmitNewsletterOverlayOpened = (formId: string) => {
  useTrackEvent({
    event: "ps_newsletter_overlay_opened",
    form_id: formId,
  });
};
