import type { AxiosResponse } from "axios";
import type { GetTariffsById200Response } from "~/src/generated-sources/public";

export const getTariffsById = async (ids: string[]) => {
  const { $brain } = useNuxtApp();
  // As a uri header must not be longer than 2000 character, we need to split the ids into chunks
  const uniqueIds = Array.from(new Set(ids));
  const chunkSize = 50;
  const chunkedIds = [];

  for (let i = 0; i < uniqueIds.length; i += chunkSize) {
    chunkedIds.push(uniqueIds.slice(i, i + chunkSize));
  }

  const promises: Promise<AxiosResponse<GetTariffsById200Response, any>>[] =
    chunkedIds.map((chunk) => $brain.public.tariffs.getTariffsById(chunk));

  const tariffs: AxiosResponse<GetTariffsById200Response, any>[] = await Promise.all(
    promises.map((promise) => promise.catch((error) => error)),
  );
  return tariffs.filter((t) => t.status <= 299).flatMap((t) => t.data.items);
};
