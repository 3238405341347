import type { PublicTariffWithSummarizedPriceRanges as PublicTariff } from "~/src/generated-sources/public/api";
import type { CalculatorTariffType } from "~/types/shared/calculator";

/** Map<InitialTariff, TariffToSwitchTo> */
export type TariffSwitchesMap = Map<CalculatorTariffType, CalculatorTariffType>;

/** Represents a map with tariffs that allow to switch the tariff to another tariff.
 *
 * @param selectableTariffs A list of tariffs the user can choose from
 * @example Ökogas Klassik <-> Ökogas Vegan
 */
export const getTariffSwitchesMap = (
  selectableTariffs: CalculatorTariffType[],
): TariffSwitchesMap => {
  const tariffSwitches = selectableTariffs.filter((t) => t.tariffSwitch?.tariff);
  return new Map(tariffSwitches.map((t) => [t, t.tariffSwitch!.tariff]));
};

/** Excludes tariffs that can be switched to so they don't take up an extra TariffListItem space. */
export const getDisplayTariffsForTariffSwitches = (
  tariffs: PublicTariff[],
  tariffSwitches: TariffSwitchesMap,
  currentTariff?: PublicTariff,
  isAdmin = false,
) => {
  const MAX_TARIFFS_TO_DISPLAY_FOR_NON_ADMINS = 3;

  const excludeTariffSwitchesFromDisplayTariffsFilter = (tariff: PublicTariff) => {
    if (currentTariff) {
      // This ensures that the current tariff is never excluded from the display tariffs.
      if (tariff === currentTariff) return true;

      if (tariffIsTariffSwitch(currentTariff, tariffSwitches)) {
        const tariffSwitch = getTariffSwitch(currentTariff, tariffs, tariffSwitches);
        /** This ensures that only one instance of a tariff switch is displayed.
         *  @example Do not show Ökogas Klassik -> Ökogas Vegan if we already display Ökogas Vegan -> Klassik.
         * */
        if (tariffSwitch && tariffSwitch === tariff) return false;
      }
    }
    return !tariffIsTariffSwitch(tariff, tariffSwitches);
  };

  let displayTariffs = tariffs.filter(excludeTariffSwitchesFromDisplayTariffsFilter);

  if (!isAdmin && tariffs.length > MAX_TARIFFS_TO_DISPLAY_FOR_NON_ADMINS) {
    displayTariffs = displayTariffs.slice(0, MAX_TARIFFS_TO_DISPLAY_FOR_NON_ADMINS);
  }
  return displayTariffs;
};

export const isDisplayTariffSelected = (
  selectedTariff: PublicTariff | undefined,
  displayTariff: PublicTariff,
  tariffs: PublicTariff[],
  tariffSwitches: TariffSwitchesMap,
) => {
  if (!selectedTariff) return false;

  const tariffIsSelected = selectedTariff.id === displayTariff.id;

  const tariffSwitch = getTariffSwitch(displayTariff, tariffs, tariffSwitches);
  const tariffSwitchIsSelected = selectedTariff.id === tariffSwitch?.id;

  return tariffIsSelected || tariffSwitchIsSelected;
};

/** Wether or not a tariff is part of a tariff switch
 * and is therefore not a standalone tariff. */
const tariffIsTariffSwitch = (
  tariff: PublicTariff,
  tariffSwitches: TariffSwitchesMap,
) => {
  const tariffSwitchesValues = Array.from(tariffSwitches.values());
  return tariffSwitchesValues.find((t) => mapTariffToTariffProduct(tariff, t));
};

/** Get the tariff switch for a tariff if exists.
 *
 *  @param tariff The tariff to find the tariff switch for.
 *  @param tariffs The tariffs to find the tariff switch in.
 *  @returns The tariff of the tariff switch or undefined if it does not exist.
 *  */
export const getTariffSwitch = (
  tariff: PublicTariff,
  tariffs: PublicTariff[],
  tariffSwitches: TariffSwitchesMap,
): PublicTariff | undefined => {
  const tariffSwitchesKeys = Array.from(tariffSwitches.keys());
  const tariffSwitch = tariffSwitchesKeys.find((t) =>
    mapTariffToTariffProduct(tariff, t),
  );

  let tariffToSwitchTo: CalculatorTariffType | undefined;

  if (tariffSwitch) {
    /** @example Ökogas Klassik -> Ökogas Vegan */
    tariffToSwitchTo = tariffSwitch.tariffSwitch?.tariff;
  } else if (tariffIsTariffSwitch(tariff, tariffSwitches)) {
    /**
     * @example We have the relation Ökogas Klassik -> Ökogas Vegan.
     * If @param tariff is Ökogas Vegan, return Ökogas Klassik.
     * */
    tariffToSwitchTo = tariffSwitchesKeys.find(
      (t) => t.tariffSwitch && mapTariffToTariffProduct(tariff, t.tariffSwitch.tariff),
    );
  }

  if (tariffToSwitchTo) {
    return tariffs.find((t) => mapTariffToTariffProduct(t, tariffToSwitchTo!));
  }
};
