import { createGtm } from "@gtm-support/vue-gtm";
import { useGetCurrentCustomerProperties } from "~/composables/useCustomerTracking";
import config from "~/config";

let initialPage = true;

const getRouteType = () => {
  const routeType = initialPage ? "initial" : "pageChange";
  initialPage = false;

  return routeType;
};

// TODO: The only use of this plugin is the emission of a content-view event on route change. It does not load the GTM script and neither do we need it for our consent logic. Perhaps we can remove this plugin and emit the content-view event ourselves.
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(
    createGtm({
      id: config.googleAnalytics.gtmId,
      defer: false,
      enabled: true,
      debug: false,
      loadScript: false,
      vueRouter: useRouter(), // emits a content-view event on route change
      vueRouterAdditionalEventData: () => ({
        spa_type: getRouteType(),
        ...useGetCurrentCustomerProperties(),
      }),
      trackOnNextTick: false,
    }),
  );
});
