import {
  gasRecommendationConsumption,
  powerRecommendationConsumption,
} from "./recommendationSetup";
import getTotalConsumption from "./getTotalConsumption";
import type { AlternativeImpactContract } from "~/types/shared/impact-calculator";
import { TariffType, type Contract } from "~/src/generated-sources/public";

/**
 * One kWh of "normal" gas emits 0.201 kg of CO2. So our "co2 neutral" gas saves 0.201 kg of CO2 per kWh.
 */
const GAS_CO2_EQUIVALENT_PER_KWH = 201;

/**
 * One kWh of "Polarstern" gas emits 0.028292 kg of CO2.
 */
const POLARSTERN_GAS_CO2_EQUIVALENT_PER_KWH = 28.292;

/**
 * One kWh of "normal" power emits 0.350 kg of CO2. So our "co2 neutral" power saves 0.350 kg of CO2 per kWh.
 */
const POWER_CO2_EQUIVALENT_PER_KWH = 350;

/**
 * One kWh of "Polarstern" power emits 0 kg of CO2.
 */
const POLARSTERN_POWER_CO2_EQUIVALENT_PER_KWH = 0;

/**
 * Calculate the CO2 savings for a given consumption and tariff type.
 * @param consumption The consumption in kWh.
 * @param type The tariff type.
 * @returns The CO2 savings in rounded kg.
 */
export const calculateCo2Savings = (consumption: number, type: TariffType) => {
  const gasSavingsPerKwH =
    GAS_CO2_EQUIVALENT_PER_KWH - POLARSTERN_GAS_CO2_EQUIVALENT_PER_KWH;
  const powerSavingsPerKwH =
    POWER_CO2_EQUIVALENT_PER_KWH - POLARSTERN_POWER_CO2_EQUIVALENT_PER_KWH;

  const co2Save = type === TariffType.Gas ? gasSavingsPerKwH : powerSavingsPerKwH;

  return Math.round((consumption * co2Save) / 1000);
};

/**
 * Calculate the total reduction of emitted co2 thanks to using Polarstern instead of a "normal" provider.
 * @param contracts The contracts to calculate the reduction for.
 * @param recommendationCount The amount of recommendations of the customer.
 * @returns The total reduction of emitted co2 in kg.
 */
export const calculateContractsCo2Reduction = (
  contracts: (Contract | AlternativeImpactContract)[],
  recommendationCount: number,
) => {
  const gasContracts = contracts.filter((c) => c.type === "gas");
  const powerContracts = contracts.filter((c) => c.type === "power");

  const totalGasConsumption =
    getTotalConsumption(gasContracts) +
    recommendationCount * gasRecommendationConsumption;
  const totalPowerConsumption =
    getTotalConsumption(powerContracts) +
    recommendationCount * powerRecommendationConsumption;

  const gasReduction = calculateCo2Savings(totalGasConsumption, TariffType.Gas);
  const powerReduction = calculateCo2Savings(totalPowerConsumption, TariffType.Power);

  return gasReduction + powerReduction;
};
