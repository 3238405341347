<template>
  <!-- TODO: Make this work CustomerTypeField.vue -->
  <div class="toggle--wrapper" aria-label="Auswahl zwischen">
    <div :class="{ 'fw-bold': !isToggledOn }">
      {{ offText }}
    </div>
    <FormKit
      v-model="isToggledOn"
      :outer-class="'my-0' + ' ' + outerFormkitClass"
      type="toggle"
      :name="name || 'toggle'"
      :disabled="props.disabled"
      :aria-label="`Auswahl ${onText}`"
    />
    <div :class="{ 'fw-bold': isToggledOn }">
      {{ onText }}
    </div>
  </div>
</template>

<script setup lang="ts">
const emits = defineEmits<{ (e: "update", value: boolean): void }>();
const props = defineProps<{
  initialValue?: boolean;
  disabled?: boolean;
  outerFormkitClass?: string;
  onText: string;
  offText: string;
  name?: string;
}>();

const isToggledOn = ref(props.initialValue || false);

watch(isToggledOn, (newValue) => {
  emits("update", newValue);
});
</script>

<style lang="scss">
.toggle--wrapper {
  display: flex;
  gap: 0.5rem;
  margin: 0.75rem 0;
}
</style>
