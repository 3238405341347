import { getPriceRange } from "./priceCalculation";
import type {
  PublicTariff,
  PublicTariffRuntimeTypeEnum,
  PublicTariffSubTypeEnum,
  PublicTariffWithSummarizedPriceRanges,
} from "~/src/generated-sources/public";
import type {
  CalculatorTariffType,
  ProductGroup,
  ProductGroupValue,
} from "~/types/shared/calculator";

/**
 * This function filters gas or power tariffs by several different aspects:
 * - Tariff type, e.g. power, gas.
 * - Tariff sub type, e.g. "flex", "heatPump", but considering fallbacks as given in allowedTariffSubTypes
 * - Tariff runtime type, e.g. "comfort", "standard".
 * - Price ranges
 *   - If the tariff has no or empty price ranges, it is filtered out, but not for admins.
 *   - There has to be at least one price range that matches the consumption (including low consumption for htnt tariffs).
 *
 * @param tariffs The tariffs to filter.
 * @param chosenProductGroup The chosen product group in the tariff calculator.
 * @param options Some options to configure the filtering.
 * @param options.consumption The consumption to filter by.
 * @param options.lowConsumption The low consumption to filter by.
 * @param options.isAdmin Whether the user is an admin.
 * @param options.hasAdditionalMeter Whether the customer has an additional meter.
 * @param options.hasDoubleMeter Whether the customer has a double meter.
 * @returns The filtered tariffs.
 */
export const filterTariffs = (
  tariffs: PublicTariffWithSummarizedPriceRanges[],
  chosenProductGroup: ProductGroup,
  options: {
    consumption: number;
    lowConsumption: number | null;
    isAdmin: boolean;
    hasAdditionalMeter: boolean;
    hasDoubleMeter: boolean;
  },
) => {
  return (
    tariffs
      .filter((t) => {
        const initialSubTypes = chosenProductGroup.allowedCalculatorTariffTypes
          .filter((t2) => t2.tariffType === t.type)
          .map((t2) => t2.tariffSubType);
        const initialRunTimeTypes = chosenProductGroup.allowedCalculatorTariffTypes
          .filter((t2) => t2.tariffType === t.type)
          .map((t2) => t2.runTimeType);

        return tabConfigAllowesTariff(
          t,
          chosenProductGroup.allowedCalculatorTariffTypes,
          t.type === "power"
            ? getAllowedPowerSubTypes(
                initialSubTypes,
                chosenProductGroup.value,
                options.hasAdditionalMeter,
                options.hasDoubleMeter,
              )
            : initialSubTypes,
          t.type === "power"
            ? getAllowedPowerRunTimeTypes(
                initialRunTimeTypes,
                chosenProductGroup.value,
                options.hasAdditionalMeter,
                options.hasDoubleMeter,
              )
            : initialRunTimeTypes,
        );
      })
      .filter(
        (t) =>
          hasEmptyPriceRanges(t, options.isAdmin) ||
          !!getPriceRange(t.priceRanges, options.consumption),
      )
      .filter(
        (t) =>
          t.lowTariffPriceRanges === undefined ||
          options.lowConsumption === null ||
          !!getPriceRange(t.lowTariffPriceRanges, options.lowConsumption),
      ) || []
  );
};

/**
 * Calculates whether the tab config allows the given tariff.
 *
 * @param tariff The tariff to check.
 * @param allowedCalculatorTariffTypes
 * @param allowedTariffSubTypes
 * @param allowedTariffRunTimeTypes
 * @param allowedTariffSubTypes
 * @param allowedTariffRunTimeTypes
 * @param allowedTariffSubTypes
 * @param allowedTariffRunTimeTypes
 * @returns Whether the tariff is allowed.
 */
export const tabConfigAllowesTariff = (
  tariff: PublicTariff,
  allowedCalculatorTariffTypes: CalculatorTariffType[],
  allowedTariffSubTypes: PublicTariffSubTypeEnum[],
  allowedTariffRunTimeTypes: PublicTariffRuntimeTypeEnum[],
) => {
  if (!allowedCalculatorTariffTypes) return true;

  if (
    allowedCalculatorTariffTypes.some((t) => {
      // Filter for "Tariff base type, further classified by its sub type", e.g. power, gas.
      const tariffTypeMatches = t.tariffType === tariff.type;

      // Filter for "The tariff\'s sub type, used to narrowing down tariffs.", e.g. "flex", "heatPump"
      const tariffSubTypeMatches = allowedTariffSubTypes.includes(tariff.subType);

      // Filter for "runtime type", e.g. "comfort", "standard"
      const tariffRunTimeTypeMatches = allowedTariffRunTimeTypes.includes(
        tariff.runtimeType,
      );
      return tariffTypeMatches && tariffSubTypeMatches && tariffRunTimeTypeMatches;
    })
  ) {
    return true;
  }

  return false;
};

/**
 * There are tariffs that have no price ranges or price ranges,
 * that range from 0 to 0. We only want to display them for admins.
 *
 * @param tariff The tariff to check.
 * @param isAdmin Whether the user is an admin.
 * @returns Whether the tariff has no price ranges or only empty price ranges.
 */
const hasEmptyPriceRanges = (
  tariff: PublicTariffWithSummarizedPriceRanges,
  isAdmin: boolean,
) => {
  if (!isAdmin) return false;

  const hasNoPriceRanges = tariff.priceRanges?.length === 0;
  const hasEmptyPriceRanges = tariff.priceRanges?.every(
    (priceRange) => priceRange.from.value === 0 && priceRange.to.value === 0,
  );

  return hasNoPriceRanges || hasEmptyPriceRanges;
};

/**
 * Returns the allowed power sub types for the given product group.
 * There is some fallback logic happening if the user does not have an additional meter or a double meter.
 *
 * @param initialSubTypes
 * @param chosenProductGroup The chosen product group.
 * @param hasAdditionalMeter Whether the customer has an additional meter.
 * @param hasDoubleMeter Whether the customer has a double meter.
 * @returns The allowed power sub types.
 */
export const getAllowedPowerSubTypes = (
  initialSubTypes: PublicTariffSubTypeEnum[],
  chosenProductGroup: ProductGroupValue | undefined,
  hasAdditionalMeter: boolean,
  hasDoubleMeter: boolean,
): PublicTariffSubTypeEnum[] => {
  if (!chosenProductGroup) {
    return initialSubTypes;
  }

  switch (chosenProductGroup) {
    case "nightStorageHeating":
      if (hasAdditionalMeter) {
        return ["nightStorageHeating"];
      }
      if (!hasAdditionalMeter && hasDoubleMeter) {
        return ["double"];
      }
      return ["classic", "classicFlex"];
    case "heatPump":
      if (hasAdditionalMeter) {
        return ["heatPump"];
      }
      if (!hasAdditionalMeter && hasDoubleMeter) {
        return ["double"];
      }
      return ["classic", "classicFlex"];
    default:
      return initialSubTypes;
  }
};

/**
 * Returns the allowed power runtime types for the given product group.
 * There is some fallback logic happening if the user does not have an additional meter or a double meter.
 *
 * @param initialRunTimeTypes
 * @param chosenProductGroup The chosen product group.
 * @param hasAdditionalMeter Whether the customer has an additional meter.
 * @param hasDoubleMeter Whether the customer has a double meter.
 * @returns The allowed power runtime types.
 */
export const getAllowedPowerRunTimeTypes = (
  initialRunTimeTypes: PublicTariffRuntimeTypeEnum[],
  chosenProductGroup: ProductGroupValue | undefined,
  hasAdditionalMeter: boolean,
  hasDoubleMeter: boolean,
): PublicTariffRuntimeTypeEnum[] => {
  if (!chosenProductGroup) {
    return initialRunTimeTypes;
  }

  switch (chosenProductGroup) {
    // Provide fallbacks for nightStorageHeating and heatPump
    case "nightStorageHeating":
    case "heatPump":
      if (hasAdditionalMeter) {
        return initialRunTimeTypes;
      }
      if (!hasAdditionalMeter && hasDoubleMeter) {
        return initialRunTimeTypes;
      }
      return ["comfort", "standard"];
    default:
      return initialRunTimeTypes;
  }
};
