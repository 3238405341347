import type { Contract } from "~/src/generated-sources/core";
import type { PublicTariffWithSummarizedPriceRanges as PublicTariff } from "~/src/generated-sources/public";
import getCurrentTariffId from "~/utils/customer-area/getContractCurrentTariff";

export const isFlexTariff = (tariff: PublicTariff | null) => {
  if (!tariff) return false;
  return tariff.subType === "classicFlex";
};

export const isOwnPowerTariff = (tariff: PublicTariff | null) => {
  if (!tariff) return false;
  return tariff.subType === "own";
};

/**
 * Returns the tariff that applies to the passed contract.
 *
 * @param tarrifs The tariffs to search in.
 * @param contract The contract to get the tariff for.
 * @returns The tariff that applies to the passed contract or null if no tariff applies.
 */
const getTariffForContract = (tarrifs: PublicTariff[], contract: Contract) => {
  return tarrifs.find((tarrif) => tarrif.id === getCurrentTariffId(contract)) || null;
};

export default getTariffForContract;
